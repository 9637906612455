<template>
  <div class="h-full w-full flex-grow relative">
    <div class="modal-overlay" :style="[showLoading ? { display: 'flex' } : { display: 'none' }]">
      <div class="w-full h-full flex justify-center items-center">
        <div class="loading-spinner" />
      </div>
    </div>

    <ModalUsers ref="modalformeUser" :refresh-table="refreshTable" show-modal-sync="showmodal" />
    <Modalprojects ref="modalformeprojects" show-modal-sync="showmodal" />
    <ModalAddJetons ref="modalformeAddJetons" :refresh-table="refreshTable" show-modal-sync="showmodal" />

    <t-table :headers="getHeader" :data="paginatedProjects" class="admin-usr-table h-full">
      <template v-slot:thead="props">
        <thead :class="props.theadClass">
          <tr :class="['h-auto md:h-10 py-2', props.trClass]">
            <!-- <template v-for="(item, index) in props.data" :class="[props.thClass]"> -->
            <th v-for="(item, index) in props.data" :key="index" :class="{ hidden: item.text == 'id', [props.thClass]: true }">
              {{ item.text }}
            </th>
            <!-- </template> -->
          </tr>
        </thead>
      </template>
      <template slot="row" slot-scope="props">
        <tr :class="['text-center h-auto md:h-8', props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '']">
          <td class="hidden">
            {{ props.row.id }}
          </td>
          <!-- eslint-disable -->
          <td class="w-1/12" v-html="highlightText(props.row.username)" />
          <td class="w-3/12" v-html="highlightText(props.row.name)" />
          <td class="w-3/12" v-html="highlightText(props.row.email)" />
          <!-- <td class="w-2/12" v-html="highlightText(props.row.company)" /> -->
          <td v-if="isRtk === true || roleName === 'admin'" class="w-1/12" v-html="highlightText(props.row.rtkLabel)" />
          <td v-if="role === 'manager' && (isTeria == true || roleName === 'admin')" class="w-1/12" v-html="highlightText(props.row.teriaLabel)" />
          <td
            v-if="roleName === 'admin' && (role === 'user' || role === 'supervisor')"
            class="w-1/6"
            v-html="highlightText(props.row.managerUsername)"
          />
          <td v-if="role === 'manager'" class="w-1/12" v-html="highlightText(props.row.usedJetons)" />
          <td v-if="role === 'manager'" class="w-1/12" v-html="highlightText(props.row.remainingJetons)" />
          <!-- eslint-enable -->
          <td :class="['w-1/12', 'text-center']">
            <t-dropdown variant="raphal">
              <div
                slot="trigger"
                slot-scope="{ mousedownHandler, keydownHandler, focusHandler, blurHandler }"
                class="flex"
              >
                <button
                  class="rounded px-3 py-2 bg-raphal-valider text-white hover:bg-gray-500"
                  @mousedown="mousedownHandler"
                  @keydown="keydownHandler"
                  @focus="focusHandler"
                  @blur="blurHandler"
                >
                  <svg
                    version="1.1"
                    viewBox="0 0 16 16"
                    class="text-gray-1 fill-current svg-icon svg-fill"
                    heigth="20"
                    style="width: 20px"
                  >
                    <path
                      pid="0"
                      d="M13 7a2 2 0 1 1 .001 3.999A2 2 0 0 1 13 7zM8 7a2 2 0 1 1 .001 3.999A2 2 0 0 1 8 7zM3 7a2 2 0 1 1 .001 3.999A2 2 0 0 1 3 7z"
                    />
                  </svg>
                </button>
              </div>
              <div slot-scope="{ hide }">
                <button
                  class="block w-full px-4 py-2 text-left text-gray-800 hover:text-white hover:bg-gray-1"
                  @click.stop="editRow(props.row)"
                  @click="hide"
                >
                  Modifier
                </button>
                <button
                  class="block w-full px-4 py-2 text-left text-gray-800 hover:text-white hover:bg-gray-1"
                  @click.stop="deleteUser(props.row)"
                  @click="hide"
                >
                  Supprimer
                </button>
                <button
                  v-if="role != 'supervisor'"
                  class="block w-full px-4 py-2 text-left text-gray-800 hover:text-white hover:bg-gray-1"
                  @click.stop="affectUser(props.row)"
                  @click="hide"
                >
                  Affecter à un Projet
                </button>
                <button
                  v-if="userRole == 'admin' && role == 'manager'"
                  class="block w-full px-4 py-2 text-left text-gray-800 hover:text-white hover:bg-gray-1"
                  @click.stop="addJetons(props.row)"
                  @click="hide"
                >
                  Ajouter Jetons
                </button>
              </div>
            </t-dropdown>
          </td>
        </tr>
      </template>
      <template slot="tfoot" slot-scope="{ tfootClass, trClass, tdClass, renderResponsive }">
        <tfoot :class="tfootClass">
          <tr :class="['align-bottom', trClass]">
            <td :class="tdClass" :colspan="renderResponsive ? 2 : role === 'manager' ? 7 : 6">
              <t-pagination
                ref="userPagination"
                :hide-prev-next-controls="renderResponsive"
                :total-items="lenUsers"
                :value="1"
                :per-page="nbrows"
                :class="{
                  'ml-auto': !renderResponsive,
                  'mx-auto': renderResponsive
                }"
                @change="getPage"
              />
            </td>
          </tr>
        </tfoot>
      </template>
    </t-table>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import ModalUsers from './Modalusers.vue';
import Modalprojects from './ModalProjects.vue';
import ModalAddJetons from './ModalAddJetons.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'UserManagement',
  components: { ModalUsers, Modalprojects, ModalAddJetons },
  props: {
    role: { type: String, default: '' },
    userRole: { type: String, default: '' },
    selectedRole: { type: String, default: '' },
    nbrows: { type: Number, default: 0 }
  },
  data() {
    return {
      projets: null,
      lenUsers: 0,
      showmodal: false,
      numPage: 1,
      reset: false,
      search: '',
      showLoading: false
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      roleName: 'Auth/roleUser',
      isRtk: 'Auth/isRtk',
      isTeria: 'Auth/isTeria'
    }),
    paginatedProjects() {
      if (this.getUsersPaginated == null || this.getUsersPaginated.items == null) {
        return [];
      }
      const colsToFilterFrom = [
        'username',
        'name',
        'email',
        'company',
        'rtkLabel',
        'teriaLabel',
        'managerUsername',
        'usedJetons',
        'totalJetons'
      ];
      const filter = `${this.$parent.$parent.userFilter[this.role]}`.toLowerCase();
      let slicedProjects = this.getUsersPaginated.items.map((row) => {
        // row['company'] = row['company'] ? row['company']['name'] : '';
        row['rtkLabel'] = row['rtk'] == true ? 'OUI' : 'NON';
        row['teriaLabel'] = row['teria'] == true ? 'OUI' : 'NON';
        row['name'] = `${row['firstName']} ${row['lastName']}`;
        row['remainingJetons'] = row['totalJetons'] - row['usedJetons'];
        row['managerUsername'] = row.manager != null ? row.manager.username : '';
        return row;
      });
      slicedProjects = slicedProjects.filter((row) => {
        let match = false;
        for (let col of colsToFilterFrom) {
          match ||= `${row[col]}`.toLowerCase().includes(filter);
        }
        return match;
      });
      return slicedProjects;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.lenUsers = slicedProjects.length;
      // console.log('sliced', this.lenUsers)
      // return slicedProjects.slice(this.nbrows * (this.numPage - 1), this.nbrows * this.numPage);
    },
    getHeader() {
      // TODO: add 'company' column
      let header =
        this.role == 'user' || this.role == 'supervisor'
          ? ['id', 'Pseudo', 'Nom  Prénom', 'Adresse mail']
          : ['id', 'Pseudo', 'Nom Prénom', 'Adresse mail', 'Jetons Consommés', 'Jetons Restants'];
      if (this.isRtk == true || this.roleName == 'admin') {
        header.splice(4, 0, 'RTK');
        if (this.roleName == 'admin' && (this.role == 'user' || this.role == 'supervisor')) {
          header.push('Manager');
        }
      }
      if ((this.role === 'manager') && (this.isTeria == true || this.roleName == 'admin')) {
        header.splice(5, 0, 'Teria');
      }
      header.push('Actions');
      return header;
    }
  },
  mounted() {
    this.showLoading = true;
  },
  beforeUpdate() {
    this.getUsersPaginated == null;
  },
  methods: {
    editRow(item) {
      this.showmodal = true;
      if (this.role === 'manager') {
        this.$refs.modalformeUser.toggleModal(item, 'Modifier Manager', true, 'manager', 2);
      } else if (this.role === 'supervisor') {
        this.$refs.modalformeUser.toggleModal(item, 'Modifier Superviseur', true, 'supervisor', 4);
      } else {
        this.$refs.modalformeUser.toggleModal(item, 'Modifier Utilisateur', true, 'user');
      }
    },
    affectUser(item) {
      this.showmodal = true;
      this.$refs.modalformeprojects.toggleModal(item);
    },
    addJetons(item) {
      this.showmodal = true;
      this.$refs.modalformeAddJetons.toggleModal(item);
    },
    deleteUser(item) {
      const index = this.getUsersPaginated.items.indexOf(item);
      // eslint-disable-next-line
      ToastConfirm.fire({
        title: "Voulez-vous vraiment supprimer cet utilisateur ?"
      }).then((result) => {
        if (result.isConfirmed) {
          this.getUsersPaginated.items.splice(index, 1);
          this.$apollo
            .mutate({
              mutation: gql`
                mutation deleteUser($username: String!) {
                  deleteUser(username: $username) {
                    success
                  }
                }
              `,
              variables: {
                username: item.username
              }
            })
            .then((data) => {
              // Result
              if (data.data.deleteUser != null) {
                // eslint-disable-next-line
                Toast.fire({
                  icon: 'success',
                  title: 'Opération effectuée avec succès !'
                });
              } else {
                // eslint-disable-next-line
                Toast.fire({
                  icon: 'error',
                  title: 'erreur !'
                });
              }
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.error(error);
            });
        }
      });
    },
    getPage(num) {
      this.showLoading = true;
      this.numPage = num;
    },
    refreshTable() {
      this.$apollo.queries.getUsersPaginated.refetch();
    },
    /**
     * Highlight text that matches the search
     */
    highlightText(txt) {
      if (!txt || !this.role || !this.$parent.$parent.userFilter[this.role]) {
        return txt;
      }
      const filter = `${this.$parent.$parent.userFilter[this.role]}`.toLowerCase();
      const found = `${txt}`.toLowerCase().includes(filter);
      if (!found) {
        return txt;
      }
      const rgx = new RegExp(filter, 'gi');
      return txt.replace(rgx, (foundTxt) => `<strong>${foundTxt}</strong>`);
    }
  },
  apollo: {
    getUsersPaginated: {
      query: gql`
        query getUsersData($role: String!, $search: String, $limit: Int!, $offset: Int!) {
          getUsersPaginated(role: $role, search: $search, limit: $limit, offset: $offset) {
            count
            items {
              id
              username
              firstName
              lastName
              email
              totalJetons
              usedJetons
              manager {
                id
                username
                teria
                company {
                  id
                  name
                }
              }
              role {
                id
                name
              }
              company {
                id
                name
              }
              supervisors {
                id
                email
                username
                manager {
                  id
                  username
                }
              }
              paramsNtrip {
                user
                host
                port
                mountPoint
              }
              rtk
              teria
              useJetons
              hasAllUsers
              usePgoc
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables() {
        // Use vue reactive properties here
        return {
          role: this.role,
          search: this.search,
          limit: this.nbrows,
          offset: this.numPage-1
        };
      },
      result({ data }) {
        this.lenUsers = data.getUsersPaginated.count;
        this.showLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(16, 16, 16, 0.7);
  z-index: 100;
  cursor: default;
}
</style>
