<template>
  <input
    v-model="searchValue"
    type="text"
    class="block border border-grey-light w-full px-3 py-2 rounded"
    placeholder="Rechercher..."
    @input="debouncedOnInput"
  >
</template>

<script>

export default {
  name: 'UserSearch',
  components: {
  },
  props: {
    target: { type: String, default: 'user' }
  },
  data() {
    return {
      previousValue: '',
      searchValue: '',
      stoppedTyping: false
    };
  },
  computed: {
  },
  created() {
    this.debouncedOnInput = this.debounce(this.launchFilter, 800);
  },
  methods: {
    /**
     * Delay function execution in millisecond
     * @param fn {Function}
     * @param delay {Int}
     */
    debounce(fn, delay) {
      let timer;
      return function(...args) {
        clearTimeout(timer);
        timer = setTimeout(() => fn.apply(this, args), delay);
      }
    },
    /**
     * Run filtering
     */
    launchFilter() {
      this.stoppedTyping = true;
      if (this.previousValue !== this.searchValue) {
        const ancestor = this.$parent.$parent;
        if (ancestor) {
          let targetTable = (this.target === 'manager') ? ancestor.$refs.managerTable : (this.target === 'supervisor') ? ancestor.$refs.supervisorsTable : ancestor.$refs.userTable;
          if (targetTable) {
            targetTable.search = this.searchValue;
            targetTable.$refs.userPagination.currentPage = 1;
            targetTable.refreshTable();
          }
          ancestor.userFilter[this.target] = this.searchValue;
        }
        this.previousValue = this.searchValue;
      }
    },
    /**
     * Reset field value to empty
     */
    resetValue() {
      this.searchValue = "";
    }
  }
}
</script>

<style scoped>
</style>