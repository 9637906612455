<template>
  <div>    
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen min-w-1/2 flex flex-col">
        <div class="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full relative">
            <div v-if="fetching" class="-mx-6 -my-8 h-full w-full flex absolute flex-grow rounded-md z-10 p-0 justify-center opacity-50 text-xl text-center font-bold text-white items-center bg-raphal-valider">
              <svg class="w-1/5 animate-spin h-9 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
            </div>

            <h1 v-if="operationType=='uploadProject'" class="mb-8 text-3xl text-center">
              Envoyer Projet
            </h1>
            <h1 v-else-if="operationType=='updateUser'" class="mb-8 text-3xl text-center">
              Modifier Utilisateur
            </h1>
            <h1 v-else-if="operationType=='updateProject'" class="mb-8 text-3xl text-center">
              Mettre à jour un projet
            </h1>
            <button v-if="operationType!='updateUser'" class="w-full text-center py-2 rounded bg-raphal-valider hover:bg-raphal-termine col-span-2 my-1 border-raphal-valider flex items-center justify-center text-white cursor-pointer  focus:outline-none" 
                    :class="[files!=null ? 'bg-raphal-traiter' : '']"
                    @click="$refs.file.click()"
            >
              <span class="text-center" style="width: 32px; height: 32px; ">
                <svg style="width: 32px; height: 32px" class=" fill-current" fill="none" viewBox="0 0 500 500">
                  <g>
                    <path d="m241.1 68c-2.2 1.1-24.9 22.9-50.4 48.5-37.9 38-46.7 47.5-48 51.9-1 3.3-1.3 7.6-0.7 10.9 0.7 3.6 2.7 7.1 6 10.4 3.2 3.2 6.8 5.2 10.3 5.9 3.3 0.6 7.6 0.3 10.9-0.7 4.3-1.3 11.1-7.3 32.2-28.3l26.8-26.7c0.7 148.7 0.8 151.3 3.4 154.8 1.5 2 4.5 5 6.6 6.5 2.4 1.9 6.4 3.1 10.8 3.4 5.6 0.4 8-0.2 11.8-2.7 2.6-1.8 6.1-6 7.8-9.3l3.1-6.1c0-112 0.4-144.5 1-144.5 0.5 0 12.6 11.5 26.8 25.6 20.2 20.2 26.9 26 31.2 27.3 3.3 1 7.6 1.3 10.8 0.7 3.6-0.7 7.1-2.7 10.4-5.9 3.3-3.3 5.3-6.8 5.9-10.4 0.6-3.3 0.4-7.6-0.6-10.9-1.3-4.4-10.1-13.9-48-51.9-25.5-25.6-48.3-47.4-50.8-48.5-2.4-1.1-6.4-2-8.8-2-2.4 0-6.2 0.9-8.5 2zm-128.9 216.6c-2.2 0.7-5.2 2.3-6.6 3.6-1.4 1.3-3.7 4.8-5.1 7.8-2.1 4.6-2.5 9-2.5 29.8 0 13.5 0.6 28.3 1.3 32.9 0.8 4.7 3.3 12.6 5.6 17.7 2.5 5.4 7.1 12.2 11.3 16.6 4 4.1 10.8 9.4 15.2 11.8 4.4 2.4 12.2 5.3 17.4 6.5 8.4 2 21 2.2 105.9 1.8 93.4-0.4 96.6-0.5 103.8-3.3 4.1-1.5 11.1-5.1 15.5-8.1 4.4-2.9 10-7.7 12.4-10.7 2.5-3 6-8.5 7.8-12.2 1.9-3.8 4.2-9.6 5.2-12.9 1.1-4.2 1.7-16.2 1.8-37.6 0-29.4-0.1-31.7-2.7-35.1-1.5-2-4.4-5-6.5-6.6-2.9-2.1-5.8-2.8-11.5-2.8-5.8 0-8.9 0.8-12.2 3-2.4 1.7-5.4 5.1-6.7 7.5-1.9 3.6-2.5 9.6-3.1 33.8-0.6 27-0.9 29.8-3.5 33.3-1.5 2.1-4.9 4.9-7.4 6.2-4.3 2.2-11.8 2.3-97.7 2.3-87.4 0-93.2-0.1-97.7-2.4-2.9-1.6-5.8-4.7-7.8-8.5-2.8-5.6-3-7.7-3-31.1 0-15.4-0.6-27-1.5-29.9-0.9-2.6-3.2-6.4-5.1-8.5-2-2.1-5.7-4.4-8.3-5.1-2.7-0.7-6-1.2-7.5-1.2-1.5 0.1-4.6 0.7-6.8 1.4z" />
                  </g>
                </svg>                
              </span>              
            </button> 
            <input ref="file" type="file" style="display:none" @change="toggleFile">
            <div class="w-full text-black text-xs mb-0" style="text-overflow:ellipsis;white-space:nowrap;overflow:hidden;">
              <span v-if="files!=null" class="self-center text-base">{{ files.name }}</span>
            </div>

            <t-rich-select v-if="operationType!='updateProject'"
                           v-model="userId"
                           :fetch-options="fetchOptions"
                           placeholder="Utilisateur"
                           search-box-placeholder="Chercher par Username"
                           value-attribute="id"
                           text-attribute="username" 
                           :clearable="true"
                           class="pb-4"
            >
              <template
                slot="label"
                slot-scope="{ option }"
              >
                <div class="flex">
                  <div class="flex flex-col ml-2 text-gray-800">
                    <strong>{{ option.raw.username }}</strong>
                    <span class="text-sm leading-tight text-gray-700">{{ option.raw.email }}</span>
                  </div>
                </div>
              </template>
            </t-rich-select>
            
            <button
              class="w-full text-center py-3 rounded bg-raphal-total text-white hover:bg-raphal-termine focus:outline-none my-1"
              @click="importProject"
            >
              OK
            </button>
            <button 
              class="w-full text-center py-3 rounded  bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
              @click="closeDialog"
            >
              Annuler
            </button>             
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import gql from "graphql-tag";
import { apolloClient } from "../vue-apollo";
import { mapGetters } from "vuex";

export default {
  name: "ModalUpdateProject",
  components: {
  },
  props: {
    fetching: { type: Boolean, default: false }
  },
  data() {
    return {
      showModal: false,
      userId: '',
      files: null,
      idProject: null,
      operationType: '',
      // fetching: this.$parent.fetching
    };
  },
  computed: {
    ...mapGetters({
      isAuth: "Auth/isAuth",
      user: "Auth/user",
      roleName: "Auth/roleUser"
    })
  },
  methods: {    
    toggleModal (operationType, idProject) {
      this.showModal = !this.showModal;
      this.operationType = operationType;
      this.idProject = idProject;
    },
    toggleFile ({ target: { files = [] } }) {
      if (!files.length) {
        this.files = null
        return;
      }
      const file = files[0];
      /*Object.defineProperty(file, 'name', {
        writable: true,
        value: file.name.replaceAll(/[^a-z0-9_.]/gi, '_')
      });*/
      this.files = file;
    },
    async fetchOptions (q) {
      try {
        let all = false 
        if (this.roleName === 'admin') {
          all = true
        }
        if (q === null) {
          q = ''
        }
        let {
          data: data
        } = await apolloClient.query({
         query: gql`query($role: String!, $searchQyery: String!, $all: Boolean!) {
            searchUser(role: $role, searchQyery: $searchQyery, all: $all) {
              id
              username
              email
            }
          }`,
          variables: {
            role: "user", 
            searchQyery: q,
            all: all
          }
        });
       
        return { results: data.searchUser }

      } catch (err) {
        // eslint-disable-next-line
        Toast.fire({
          icon: "error",
          title: err.nonFieldErrors[0].message
        });
      }     
    },
    cleanForm () {
      this.userId = '';
      this.files = null;
    },
    closeDialog () {
      this.showModal = false; 
      this.cleanForm();
    },
    importProject () {     
      let query = null;
      let vars = null;
      this.$parent.fetching = true;
      if (this.operationType === "uploadProject") {
        if (!this.files) {
          // eslint-disable-next-line
          ToastAlert.fire({
            title: "Veuillez sélectionner le fichier à importer !"
          });
          this.$parent.fetching = false;
          return;
        }
        if (this.userId === "") {
          // eslint-disable-next-line
          ToastAlert.fire({
            title: "Veuillez sélectionner l'utilisateur !"
          });
          this.$parent.fetching = false;
          return;
        }
        query = gql`
          mutation uploadFile($file: Upload!, $idUser: String!) { uploadFile(file: $file, idUser: $idUser) { success, errors }
        }`;
        vars = {
          file: this.files, 
          idUser: this.userId
        }
      } else if (this.operationType === "updateUser") {
        query = gql`
          mutation changeProjectUser($idProject: Int!, $idUser: Int!) { changeProjectUser(idProject: $idProject, idUser: $idUser) { success, errors }
        }`;
        vars = {
          idProject: this.idProject,
          idUser: this.userId
        }
      } else if (this.operationType === "updateProject") {
        query = gql`
          mutation adminUploadUpdateMutation($file: Upload!) { adminUploadUpdateMutation(file: $file) { success }
        }`;
        vars = {
          file: this.files
        }
      }
      this.$apollo.mutate({
        client: "apolloProjectClient",
        mutation:query, 
        variables: vars,
        context: {
          hasUpload: true
        }
      }).then((data) => {
        // Result
        const res = data.data[Object.keys(data.data)[0]];
        if (res.success==true) {
          // eslint-disable-next-line
            Toast.fire({
            icon: "success",
            title: "Opération effectuée avec succès !"
          });
          this.closeDialog();      
        } else {
          let message = "Fichier Invalide";
          if (res.errors.indexOf("photos_mismatch") > 0) {
            message = "Le nombre de photos extraites ne correspond pas avec celui de 'data.json'";
          }
          // eslint-disable-next-line
          Toast.fire({
            icon: "error",
            title: message
          });
        }
        this.$parent.fetching = false;
      }).catch(() => {
        // eslint-disable-next-line
        Toast.fire({
          icon: "error",
          title: "erreur !"
        });
        this.$parent.fetching = false;
      })
    }
  }
};
</script>
