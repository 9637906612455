<template>
  <div class="">
    <div class="max-w-2xl md:mx-auto lg:max-w-6xl px-6 py-12">
      <div class="grid gap-20">
        <div class="text-justify" style="color: rgb(103, 103, 103)">
          <div class="font-bold text-xl pb-6" style="color: rgb(36, 40, 56)">
            Politique de confidentialité
          </div>
          <div style="color: rgb(36, 40, 56)">
            <p>
              <b>Données personnelles</b><br>
              Conformément à la loi Informatique et Libertés du 6 janvier 1978, vous disposez à tout moment d'un droit d'accès, de modification, de rectification et de suppression des données personnelles vous concernant.
              La politique de confidentialité de RAPHAL respecte la législation française en matière de protection des données personnelles et garantit la confidentialité des informations transmises.
            </p><br>
            <p>
              Conformément à l'article 34 de la loi précitée, vous pouvez exercer vos droits en nous contactant par courrier à l'adresse suivante :<br>
              RAPHAL - GEOFIT - 7 rue Kastler - 44307 NANTES
            </p><br>
            <p>
              Vous pouvez également demander à recevoir un fichier contenant l'ensemble des données personnelles en notre possession vous concernant, y compris celles que vous nous avez fournies.
              Vous pouvez à tout moment demander la suppression de ces données.
              Cela ne concerne pas les données conservées à des fins administratives, légales ou de sécurité.
            </p><br>
            <p>
              <b>Cookies</b><br>
              Le produit RAPHAL ne collecte ni n'utilise aucun cookie.
            </p><br>
            <p>
              Nous ne déposons aucun traceur ou technologie équivalente sur votre appareil via RAPHAL. Votre navigation sur notre outil ne fait l'objet d'aucune mesure automatisée à des fins de marketing, de publicité ou de suivi comportemental.
            </p><br>
            <p>
              Cependant, si vous êtes redirigé(e) vers des plateformes ou services tiers (par exemple : LinkedIn, YouTube, ou autres), ces sites peuvent appliquer leur propre politique en matière de cookies.
              Nous vous recommandons de consulter ces politiques directement sur les sites concernés.
            </p><br>
            <p>
              <b>Contact</b><br>
              Pour toute question relative à la présente politique de confidentialité, vous pouvez nous contacter :<br><br>
              <pre class="text-sm">
                Par téléphone : 02 55 99 64 16
                Par mail : contact@raphal.fr
                Par courrier : RAPHAL - GEOFIT - 7 rue Kastler - 44307 NANTES
                </pre>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  }
};
</script>

<style></style>
