<template>
  <div class="overflow-visible w-full h-auto rounded lg:flex mb-1 border-raphal-total border relative">
    <!-- <div v-if="projectData.rtk==true" class="ribbon ribbon-top-right"><span>RTK</span></div> -->
    <div
      class="w-full grid grid-cols-cardContainerProjects sm:grid-cols-cardContainerProjectsSm lg:grid-cols-cardContainerProjectsLg lg:grid-rows-cardContainerProjects"
      @mouseenter="show_button = true"
      @mouseleave="show_button = false"
    >
      <router-link
        class="w-full p-0 px-2 grid grid-cols-cardProjects gap-2 grid-flow-row-dense justify-start items-center sm:grid-cols-cardProjectsSm lg:grid-cols-cardProjectsLg"
        :to="`/projet/${idprojet}`"
      >
        <div style="width: 50px" class="stories bg-white">
          <vue-horizontal class="horizontal" :button-between="true" snap="none">
            <div class="item">
              <div
                class="ring2 rounded-full shadow-raphal border-raphal-valider bg-raphal-traiter text-white"
                :class="[iconColor]"
              >
                <div class="avatar text-white" :class="[bgcard]">
                  <span class="text-center left-2 w-full">
                    <svg v-if="isRtk" class="w-full fill-current" viewBox="0 0 512 512">
                      <path
                        d="M317.26,349.02l61.11,87.27h-117.79l-46.35-66.13-67.78-96.89h91.52c8.09,0,15.76-1.95,22.5-5.38,15.94-8.21,26.86-24.8,26.86-43.99,0-27.28-22.08-49.42-49.36-49.42h-42.92l-47.53-67.96-21.61-30.82h112.06c81.83,0,148.14,66.37,148.14,148.2,0,52.66-27.46,98.9-68.84,125.11"
                      />
                    </svg>
                    <svg v-else-if="isEasyscale" class="w-full fill-current" viewBox="0 0 100 100" style="width:92%;height:92%;padding:3px 0 0 3px">
                      <path fill="none" d="M.024 100.005-.114.29 100.31.18l.079 100.154M76.172 19.331c-11.56-7.825-23.558-12.834-40.47-6.5-14.734 5.516-21.406 14.463-25.597 30.344 3.91 0 7.423-.122 10.603.024 3.49-.009 4.36-1.666 5.832-4.876 6.138-15.573 30.662-20.213 43.109-5.388l-12.005 9.977L90.05 42.9c-1.739-9.234-7.344-17.406-12.53-22.335m-55.158 36.37-12.024-.029c2.076 15.44 19.043 33.336 40.017 33.036 21.022-.608 38.787-17.647 39.004-33.04-3.783 0-7.045.075-10.832-.055-2.479-.117-3.075 1.858-4.563 3.807-3.173 4.445-5.722 8.387-10.16 11.53-11.093 7.86-24.725 5.649-32.994-5.43l11.4-9.651-16.636-.215z" />
                      <path d="M77.718 20.716C84.158 26.964 88.38 34.024 90 42.86H56.815l12.803-10.135C55.809 18.574 33.242 22.647 25.892 39.4c-1.348 3.071-2.838 4-5.962 3.775-3.15-.227-6.331-.05-9.93-.05C13.268 28.602 21.268 18.4 34.82 13.12c15.555-6.06 29.907-3.173 42.898 7.595zM25.764 56.888h16.817L30.8 66.753c7.634 10.29 23.629 12.348 33.824 4.83 4.079-3.008 7.054-7.621 9.966-11.873 1.215-1.776 1.888-3.004 4.295-2.866 3.48.202 6.982.052 10.467.052.304 15.723-17.782 31.53-37.43 33.006C32.529 91.361 13.24 76.3 10.314 56.888z" />
                    </svg>
                    <svg v-else class="w-full fill-current" viewBox="0 0 512 512">
                      <polygon points="124.43 355.25 387.57 355.25 251.05 160.3 124.43 355.25" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </vue-horizontal>
        </div>
        <div
          class="w-full text-black font-bold text-xs mb-0"
          style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
        >
          <div class="text-raphal-valider flex items-center text-xs">
            <span
              id="textToBecopied"
              ref="clone"
              class="text-grey-darker self-center text-base"
              :title="nomProjet"
              @focus="$event.target.select()"
            >{{ nomProjet }}</span>
            <router-link :to="`/accueil`">
              <button
                class="flex items-center bg-white text-raphal-termine cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none"
                @click="copyProject(nomProjet)"
              >
                <span>
                  <svg
                    id="Capa_1"
                    fill="#000000"
                    style="margin-left: 6px"
                    height="12px"
                    width="12px"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 352.804 352.804"
                    xml:space="preserve"
                  >
                    <title>Copier Projet</title>
                    <g>
                      <path
                        d="M318.54,57.282h-47.652V15c0-8.284-6.716-15-15-15H34.264c-8.284,0-15,6.716-15,15v265.522c0,8.284,6.716,15,15,15h47.651
                          v42.281c0,8.284,6.716,15,15,15H318.54c8.284,0,15-6.716,15-15V72.282C333.54,63.998,326.824,57.282,318.54,57.282z
                          M49.264,265.522V30h191.623v27.282H96.916c-8.284,0-15,6.716-15,15v193.24H49.264z M303.54,322.804H111.916V87.282H303.54V322.804
                          z"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            </router-link>
          </div>
        </div>
        <span class="text-grey-darker text-base lg:hidden"> Etat </span>
        <div class="w-full text-black font-normal text-xs mb-0 text-raphal-valider wrapText" :title="projectState">
          <span class="text-grey-darker text-sm"> {{ projectState }}</span>
        </div>

        <span class="text-grey-darker text-base lg:hidden"> Date Création</span>
        <div class="w-full text-black font-normal text-xs mb-0 text-raphal-valider">
          <span class="text-grey-darker text-sm"> {{ timestamp(dateDepot) }}</span>
        </div>

        <span class="text-grey-darker text-base lg:hidden">Opérateur terrain</span>
        <div
          class="w-full text-black font-normal text-xs mb-0 text-raphal-valider"
          style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
        >
          <span class="text-grey-darker text-sm" :title="projectData.operateurTerrain">
            {{ projectData.operateurTerrain }}</span>
        </div>
        <span class="text-grey-darker text-base lg:hidden">Commentaire</span>
        <div
          class="w-full text-black font-normal text-xs mb-0 text-raphal-valide relative"
          style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
        >
          <div v-if="isEditing" class="w-full text-black font-normal text-xs mt-1 text-raphal-valider wrapText">
            <textarea
              ref="commentInput"
              v-model="editableComment"
              class="text-grey-darker h-full px-1 rounded text-xs w-full resize-none overflow-y-auto focus:border-gray-500"
              rows="1"
              @keydown.enter.prevent="saveComment"
              @keydown.esc="isEditing = false"
              @blur="isEditing = false"
              @click.stop.prevent
              @drag.stop.prevent
            />
          </div>
          <div v-else class="w-full text-black font-normal text-xs -mb-1 text-raphal-valider wrapText inline-block" :title="projectData.commentaire">
            <span class="text-grey-darker text-sm"> {{ projectData.commentaire }}</span>
          </div>

          <button
            v-if="show_button && !isEditing"
            class="absolute right-0 text-xs transition-opacity duration-200"
            @click.stop.prevent="editComment"
          >
            <svg
              style="width: 28px; height: 28px"
              class="fill-current"
              fill="none"
              viewBox="0 100 500 500"
            >
              <title>Modifier commentaire</title>
              <g>
                <g>
                  <path class="s0" d="m333.5 123.3c-2.2 0.7-6 2.5-8.5 3.9-2.6 1.6-30.7 29-66.6 65l-62.2 62.3c-14.9 44.9-19.2 59-19.2 60.2 0 1.3 1.3 3.6 3 5.3 1.6 1.6 4 3 5.2 3 1.3 0 15.3-4.3 31.3-9.6l29-9.6c119-118.7 125.9-126 128.8-131.8 2.8-5.7 3.2-7.4 3.2-15 0-7-0.4-9.5-2.6-14-1.4-3-4.3-7.5-6.5-9.8-2.3-2.6-6.6-5.7-10.4-7.5-5-2.4-8.1-3.2-13.5-3.4-3.9-0.2-8.8 0.3-11 1zm-190 17.6c-1.7 0.4-4.8 1.7-7 2.8-2.2 1.1-5.7 4-7.8 6.4-2.1 2.4-4.4 6.6-5.3 9.4-1.2 4.2-1.4 19.5-1.4 100.2 0 92.4 0.1 95.5 2 100.5 1.1 3.1 3.7 7 6.2 9.5 2.4 2.4 6.6 5.2 9.5 6.3 5.2 1.9 7.5 2 91.5 2 50.9 0 87.7-0.4 89.8-1 1.9-0.5 5.7-2.4 8.5-4.2 2.9-1.9 6.2-5.3 8-8.2l3-4.9c0.5-92.1 0.3-103.7-0.7-105.4-0.7-1.3-2.3-2.7-3.6-3.3-1.2-0.5-3.3-1-4.7-1-1.4 0-3.7 1.1-5.3 2.4l-2.7 2.4c-0.8 84.6-1.3 100.3-2.1 101.3-0.6 0.8-2.4 2-4 2.7-2.3 1-20.8 1.2-87.1 1l-84.3-0.3-5.5-5.5v-189.8l5-5.3c105 0.1 114-0.1 117-1.4 1.9-0.8 3.9-2.4 4.5-3.5 0.5-1.1 1-3.4 1-5 0-1.7-1-4.2-2.4-5.8l-2.4-2.7c-90.4-0.2-118.1 0-119.7 0.4z" />
                </g>
              </g>
            </svg>
          </button>
        </div>

        <span class="text-grey-darker text-base lg:hidden">INSEE</span>

        <div
          class="w-full text-black font-normal text-xs mb-0 text-raphal-valider"
          style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
        >
          <span v-if="!isAdminView" class="text-grey-darker text-sm" :title="inseeCode">
            <a class="zoom-link" @click.prevent="zoomToProject()">{{ inseeCode }}</a>
          </span>
          <span v-else>
            {{ inseeCode }}
          </span>
        </div>
        <span class="text-grey-darker text-base lg:hidden">Projet</span>

        <div class="w-full sm:col-span-3 lg:col-span-1 grid grid-cols-3 items-center justify-items-center">
          <EchartGauge :score="scores.global" :small="true" />
          <div class="text-raphal-valider flex items-center text-xs">
            <svg
              class="fill-current"
              fill="none"
              version="1.2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 420 398"
              width="32"
              height="32"
            >
              <g>
                <path
                  fill-rule="evenodd"
                  d="m314.5 102.9l6 3.9c3.3 2.1 7.5 5.5 9.3 7.5 1.7 2 4.3 5.9 8.2 13.7v142l-2.9 5.7c-1.6 3.2-4.9 7.6-7.3 9.9-2.3 2.3-6.6 5.3-14.3 9.5l-208.5-0.1-5.8-3.9c-3.1-2.1-6.9-5.2-8.3-6.7-1.4-1.6-4-5.4-8.8-13.9l-0.1-70.3c0-70 0-70.2 2.2-76 1.2-3.1 3.6-7.5 5.3-9.6 1.6-2.1 5.5-5.3 8.5-7 3-1.6 6.8-3.3 8.5-3.7 1.7-0.4 49.1-0.8 208-1zm-206.4 22.2c-1.3 1.3-2.9 3.3-3.6 4.4-1.1 1.6-1.4 12.7-1.6 55.5-0.1 38.8 0.2 53.6 1 53.7 0.6 0.2 4-2.3 7.5-5.5 3.5-3.1 11.9-10.8 18.7-17.1 6.8-6.3 14.2-13.1 16.4-15.1 2.7-2.5 5.3-3.9 8-4.2 2.4-0.4 5.4 0 7.5 0.9 1.9 0.8 7.1 5 11.5 9.3 4.4 4.4 8.6 7.9 9.3 8 0.6 0 13.5-12.1 28.5-26.9 14.9-14.8 28.4-27.4 30-28 1.5-0.6 4.4-1.1 6.5-1.1 2.5 0 4.9 0.8 7.4 2.7 2 1.6 16.3 15.2 31.8 30.3 18.2 17.8 28.4 27.1 29.1 26.5 0.5-0.6 1.1-18.7 1.4-42.3 0.5-39 0.4-41.4-1.4-45-1-2-2.7-4.4-3.7-5.3-1.7-1.5-11.1-1.8-102-2.4l-100.1-0.8zm27.5 10c1.6 0 5 0.9 7.5 2 2.4 1.1 6 3.5 7.8 5.2 2.1 2.1 3.8 5.1 4.8 8.7 0.9 3.2 1.3 6.8 0.9 8.5-0.4 1.7-1.8 4.9-3.1 7.3-1.5 2.5-4.3 5.4-7.2 7.2-2.6 1.6-6.3 3.2-8.2 3.5-2 0.4-5.8-0.1-8.8-0.9-3.9-1.2-6.2-2.7-9-5.9-2-2.4-4.3-6.1-5-8.3-0.6-2.1-1.2-5.2-1.2-6.9 0-1.6 0.9-4.8 1.9-7 1.1-2.2 2.6-4.7 3.3-5.5 0.7-0.8 2.2-2.3 3.3-3.2 1.1-1 3.8-2.4 6-3.2 2.2-0.8 5.3-1.5 7-1.5z"
                />
              </g>
            </svg>
            {{ scores.photogrammetry }}%
          </div>
          <div class="flex items-center text-xs">
            <svg
              class="fill-current"
              :class="[ionoColor]"
              fill="none"
              version="1.2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 276 368"
              width="32"
              height="32"
            >
              <title>Activité ionosphérique {{ calculateIono }}</title>
              <g>
                <path
                  fill-rule="evenodd"
                  d="m135.5 42.1c5.5-0.1 12.7 0.4 16 1 3.3 0.7 8.9 2.1 12.5 3.2 3.6 1.1 9.2 3.3 12.5 5 3.3 1.6 8.7 4.8 12 7.2 3.3 2.3 8.7 7.1 12 10.6 3.3 3.5 7.6 8.6 9.5 11.4 1.8 2.7 4.9 8.2 6.7 12 1.8 3.8 4.4 11.5 5.8 17 1.4 5.9 2.5 12.9 2.5 17 0 3.8-0.9 10.8-2.1 15.5-1.1 4.7-3.6 12.3-5.5 17-2 4.7-5.7 12.6-8.3 17.5-2.7 4.9-7.7 13.7-11.1 19.5-3.5 5.8-16.5 26.9-29 47-12.5 20.1-25.3 40.5-34.5 54.1l-2.1-3.3c-1.1-1.8-11-18.4-21.9-36.8-10.9-18.4-23.7-40.5-28.5-49-4.8-8.5-10.7-19.3-13-24-2.3-4.7-6.2-13.4-8.6-19.5-2.3-6.1-5.4-15.5-6.9-21-2.3-8.5-2.6-11.4-2.2-19 0.3-5 1.5-12.6 2.6-17 1.2-4.4 4-11.8 6.2-16.5 2.2-4.7 6.2-11.2 8.9-14.5 2.6-3.3 7.2-8.3 10.1-11 3-2.8 7.4-6.5 9.9-8.2 2.5-1.7 7.3-4.4 10.7-6.2 3.5-1.7 10.7-4.4 16.1-6 7.7-2.3 11.7-2.9 19.7-3zm-20.8 39.5c-2.8 1.5-7.9 5.3-11.2 8.4-3.3 3.2-7.4 8.4-9.2 11.6-1.7 3.3-3.9 8.6-4.7 11.9-0.9 3.3-1.6 8.7-1.6 12 0 3.3 0.7 8.6 1.5 11.7 0.8 3.2 2.8 8.4 4.4 11.5 1.6 3.2 4.5 7.6 6.3 9.8 1.9 2.2 6.3 5.9 9.9 8.3 3.5 2.4 9.3 5.2 12.9 6.3 4.1 1.2 9.4 1.9 14.5 1.9 5.1 0 10.3-0.7 14.5-1.9 3.6-1.1 8.7-3.4 11.5-5.2 2.7-1.7 6.5-4.5 8.4-6.2 1.8-1.8 5.1-5.9 7.3-9.2 2.1-3.3 4.8-8.9 5.9-12.5 1.2-4.1 1.9-9.4 1.9-14.5 0-5.1-0.7-10.4-1.9-14.5-1.1-3.6-3.9-9.4-6.3-12.9-2.4-3.6-6.3-8.2-8.8-10.3-2.5-2.1-6.8-5-9.5-6.3-2.8-1.4-7.5-3.2-10.5-4-3-0.8-8.7-1.5-12.5-1.4-3.8 0-9.4 0.7-12.3 1.5-2.8 0.8-7.6 2.6-10.5 4z"
                />
              </g>
            </svg>
            {{ scores.georeferencement }}%
          </div>
        </div>
      </router-link>

      <div
        class="pl-2 justify-center items-center p-1 grid grid-cols-1 grid-rows-3 sm:grid-rows-2 sm:grid-cols-2 sm:gap-1 lg:grid-cols-4 lg:grid-rows-1"
      >
        <button
          :disabled="isPurchaseDisabled"
          class="w-8 h-8 mx-1 shadow-raphal rounded-full border border-raphal-valider flex items-center bg-white text-raphal-termine cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed disabled:text-raphal-valider focus:outline-none"
          @click="buyProject"
        >
          <span class="text-center text-raphal-valider" style="width: 43px; height: 43px; margin-top: 18px">
            <svg
              class="w-full fill-current"
              width="20"
              height="16pt"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 358.33 436"
            >
              <title>Valider Projet</title>
              <g>
                <g>
                  <path
                    d="M351.92,368.48,425.81,474H283.38l-56-80-82-117.16H256a59.72,59.72,0,0,0,0-119.44h-51.9L146.67,75.27,120.54,38H256C355,38,435.17,118.25,435.17,217.2a178.76,178.76,0,0,1-83.25,151.28"
                    transform="translate(-76.83 -38)"
                  />
                  <polygon points="0 436 142.43 436 68.53 330.48 0 436" />
                </g>
                <g>
                  <path
                    d="M252.28,120.33a89.16,89.16,0,1,0,89.16,89.16h0A89.17,89.17,0,0,0,252.28,120.33Zm0,158.19a69.09,69.09,0,1,1,69.09-69.08h0A69.08,69.08,0,0,1,252.28,278.52Z"
                    transform="translate(-76.83 -38)"
                    fill="#fce900"
                  />
                  <circle
                    cx="175.45"
                    cy="171.49"
                    r="89.15"
                    fill="none"
                    stroke="#000"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <circle cx="175.45" cy="171.44" r="69.08" fill="#fefe98" />
                  <path
                    d="M296.35,190.32c-8-1.55-15.91-3.24-23.89-4.75a3.87,3.87,0,0,1-2.95-2.21c-4-7.12-8.12-14.21-12.19-21.34a6.09,6.09,0,0,0-5-3.44h-.65a6.07,6.07,0,0,0-4.94,3.44c-4,7.11-8.07,14.2-12,21.34a3.85,3.85,0,0,1-2.91,2.21c-7.87,1.51-15.71,3.2-23.57,4.75-2.69.52-4.78,1.66-5.73,4.36s.11,4.9,2,6.89c5.31,5.73,10.51,11.59,15.89,17.25a5.48,5.48,0,0,1,1.43,5c-1,7.76-1.9,15.51-2.71,23.28-.6,5.69,3.9,8.95,9.13,6.7,4.13-1.8,8.22-3.79,12.39-5.6,3.81-1.66,7.48-3.67,11.44-5,4,1.37,7.73,3.36,11.6,5,4.2,1.81,8.32,3.8,12.53,5.6,5.29,2.26,9.84-1,9.24-6.7-.82-7.77-1.77-15.52-2.75-23.28a5.48,5.48,0,0,1,1.44-5c5.46-5.66,10.72-11.52,16.1-17.25,1.88-2,3-4.13,2-6.89S299,190.84,296.35,190.32Z"
                    transform="translate(-76.83 -38)"
                    fill="#fc9500"
                  />
                </g>
              </g>
            </svg>
          </span>
        </button>
        <button
          class="bookmark-btn w-8 h-8 mx-1 shadow-raphal justify-center m-auto rounded-full border-0 border-raphal-valider flex items-center bg-white text-raphal-valider cursor-pointer focus:outline-none"
          :class="{ 'flag-on': projectData.bookmarked }"
          @click="toggleBookmark"
        >
          <span class="text-center" style="width: 43px; height: 43px">
            <svg style="width:20px;height:20px;position:relative;top:11px;left:6px;" class="fill-current" fill="none" xmlns="http://www.w3.org/2000/svg">
              <title v-if="projectData.bookmarked">Arrêter de suivre</title>
              <title v-else>Suivre</title>
              <path d="M1465.561 7464.973c405.36-7.23 890.24 175.45 1426.22 377.4 1065.858 444.722 3038.307 1562.967 4571.69-73.566 1545.27-1650.662 4681.19-1414.696 6025.42-551.835 526.63 373.992 874.962 615.186 1116.7 1015.07-28.649 2439.755 57.582 5083.827-38.832 7438.48-199.6 141.2-1097.406-871.214-1476.968-1055.98 0 0-1699.5-1083.476-2675.6-1083.476-881.53 0-1783.82 273.2-2579.59 1119.1-1138.46 959.67-3103.06 1221.549-4562.53 820.257-704.67-75.7-1390.708-498.692-1855.348-735.392l48.838-7270.058M528.745 15713.939c291.605 0 527.785-236.7 527.785-528.3V528.263C1056.53 236.658 819.868 0 528.263 0S0 237.14 0 528.745v14657.394c.482 291.1 237.14 527.8 528.745 527.8" transform="matrix(.0014 0 0 -.0014 1.794 23.261)" />
            </svg>
          </span>
        </button>
        <button
          :disabled="isExportDisabled"
          class="w-8 h-8 mx-1 shadow-raphal justify-center m-auto rounded-full border-0 border-raphal-valider flex items-center bg-white text-raphal-valider cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed disabled:text-raphal-valider focus:outline-none"
          @click="openExports"
        >
          <span class="text-center" style="width: 43px; height: 43px">
            <svg
              style="width: 43.5px; height: 43.5px"
              class="fill-current"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <title>Télécharger Projet</title>
              <path
                d="M253.05,68.1c102.45-.14,186.25,83.43,186.3,185.79,.05,103.5-83.18,186.85-186.66,186.91-102.35,.06-185.92-83.6-185.96-186.17-.05-102.99,83.26-186.39,186.32-186.53Zm-15.63,200.06c-.57,.19-1.15,.39-1.72,.58-10.76-10.81-21.41-21.74-32.33-32.38-6.86-6.69-15.86-6.76-22.16-.71-6.53,6.26-6.6,15.6,.38,22.66,19.77,20,39.66,39.88,59.64,59.67,7.52,7.45,16.07,7.32,23.72-.15,9.19-8.98,18.19-18.15,27.27-27.25,10.79-10.81,21.7-21.51,32.33-32.47,6.5-6.7,6.43-16.07,.34-22.16-6.21-6.21-15.15-6.13-22.17,.29-2.32,2.12-4.51,4.4-6.69,6.68-8.42,8.84-16.81,17.72-25.22,26.58-.71-.45-1.42-.9-2.14-1.35,0-2.58,0-5.17,0-7.75,0-40.73,.02-81.47-.04-122.2,0-3.12-.13-6.35-.89-9.35-1.72-6.77-7.49-11.07-14.34-11.22-7.15-.15-13.27,4.21-15.05,11.24-.76,3-.88,6.22-.88,9.35-.05,40.73-.04,81.47-.04,122.2,0,2.58,0,5.16,0,7.74Zm14.87,106.28c19.38,0,38.77,.13,58.15-.11,3.99-.05,8.34-.7,11.85-2.43,6.02-2.98,8.48-10.32,6.73-16.86-1.97-7.36-7.66-11.6-16.34-11.61-39.25-.07-78.5-.05-117.75-.02-11.44,0-18.73,6.33-18.52,15.87,.21,9.22,7.19,15.08,18.47,15.14,19.14,.1,38.28,.03,57.42,.03Z"
              />
            </svg>
          </span>
        </button>

        <button
          class="w-8 h-8 mx-1 shadow-raphal rounded-full border-0 border-raphal-valider flex items-center bg-white text-raphal-valider cursor-pointer focus:outline-none sm:col-span-2 sm:ml-7 lg:col-span-1 lg:ml-0"
          @click="delete_project"
        >
          <span class="text-center" style="width: 39px; height: 39px; margin-left: -4px; margin-top: 0px">
            <svg
              style="width: 39px; height: 39px"
              class="fill-current"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <title>Supprimer Projet</title>
              <path
                d="M260.36,43.02c-118.41,1.66-212.22,93.86-212.19,212.58,.02,118.43,93.49,212.11,212.32,212.11,118.81,0,212.25-93.68,212.18-212.23-.06-119.03-94.03-210.9-212.31-212.46Zm90.75,154.1c0,12.65-.07,12.74-11.7,17.37,0,3.58,0,7.43,0,11.27,0,36.36,.05,72.73-.02,109.09-.04,19.74-13.31,33.94-33.08,34.23-30.54,.45-61.1,.44-91.63,0-19.72-.28-33.04-14.52-33.08-34.23-.07-37.2-.02-74.39-.02-111.59,0-3.02,0-6.03,0-9.55-10.76-1.04-12.65-7.84-11.75-16.6,.31-3.02-.04-6.11,.1-9.15,.53-11.76,9.58-20.88,21.36-21.26,7.77-.25,15.56-.36,23.32,.01,5.59,.27,7.61-1.92,7.01-7.26-.22-1.92,.03-3.88-.03-5.82-.23-7.58,3.72-11.55,11.01-11.63,18.6-.21,37.21-.21,55.81,0,7.29,.08,10.93,4.06,11.07,11.63,.24,12.99,.42,12.98,13.58,12.99,5.55,0,11.11-.14,16.66,.07,11.78,.45,20.82,9.51,21.36,21.27,.14,3.05,.05,6.11,.05,9.16Z"
              />
              <path
                d="M200.11,277.21c0-18.3,.22-36.61-.12-54.91-.13-6.94,1.65-9.74,9.29-9.65,34.12,.39,68.26,.39,102.38,0,7.6-.09,9.36,2.66,9.31,9.63-.25,36.33-.06,72.66-.15,109-.04,14.89-4.05,18.81-18.71,18.82-28.02,.02-56.05,.03-84.07,0-13.51-.02-17.78-4.21-17.88-17.96-.14-18.3-.04-36.61-.04-54.91Zm34.42,4.97c0-14.98-.04-29.95,.02-44.93,.02-6.04-1.89-10.58-8.71-10.69-7.25-.12-9.33,4.51-9.32,10.96,.06,29.4,.09,58.79-.02,88.19-.02,6.54,2.49,11.38,9.2,11.31,6.81-.06,8.97-5.14,8.89-11.58-.17-14.42-.05-28.84-.06-43.26Zm34.91-.07c0-14.7-.08-29.4,.04-44.1,.05-6.27-1.41-11.32-8.78-11.45-7.28-.13-9.22,4.57-9.21,11.02,.08,29.4,.1,58.79-.02,88.19-.03,6.57,2.49,11.37,9.21,11.25,6.79-.13,8.91-5.2,8.82-11.65-.2-14.42-.06-28.84-.06-43.26Zm34.98-.51c0-14.14,.03-28.29-.01-42.43-.03-9.05-2.88-12.98-9.3-12.65-8.05,.42-8.74,6.09-8.72,12.4,.06,28.56,.11,57.13-.03,85.69-.03,6.59,1.52,12.33,8.91,12.39,7.28,.06,9.31-5.42,9.21-12.14-.2-14.42-.06-28.84-.06-43.26Z"
              />
              <path
                d="M260.08,194.04c-21.64,0-43.29,.11-64.93-.16-2.42-.03-4.81-2.17-7.22-3.33,2.53-2,5.05-5.72,7.6-5.74,43.28-.36,86.57-.36,129.85,0,2.56,.02,5.09,3.72,7.64,5.71-2.67,1.17-5.33,3.33-8.02,3.36-21.64,.28-43.28,.16-64.93,.15Z"
              />
              <path
                d="M260.53,166.59c-4.98,0-9.98,.2-14.94-.11-1.55-.1-3.01-1.55-4.51-2.39,1.33-1.01,2.65-2.86,4.01-2.89,10.23-.25,20.48-.25,30.71-.01,1.39,.03,2.73,1.83,4.1,2.81-1.47,.86-2.9,2.38-4.42,2.47-4.96,.32-9.96,.12-14.94,.12Z"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueHorizontal from 'vue-horizontal';
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import EchartGauge from '@/components/EGauge.vue';

const Toast = window.Toast

export default {
  name: 'ProjectCard',
  components: {
    VueHorizontal,
    EchartGauge
  },
  props: {
    nomProjet: { type: String, default: '' },
    idprojet: { type: String, default: '' },
    etatProjet: { type: Number, default: 0 },
    georeferenced: { type: Boolean, default: false },
    premium: { type: Number, default: 0 },
    dateDepot: { type: Date, default: Date.now() },
    projectData: { type: Object, default: null },
    validateProject: { type: Function, default: null },
    isAdminView: { type: Boolean, default: false }
  },
  data() {
    return {
      autoplay: true,
      isEditing: false,
      show_button: false,
      editableComment: this.projectData?.commentaire || "",
    };
  },
  computed: {
    ...mapGetters({
      user: 'Auth/user'
    }),
    inseeCode() {
      return this.$raphalUtils.formatInsee(this.projectData.insee);
    },
    leftImage() {
      return {
        backgroundImage: `url(${require('../assets/img/potree.svg')}`
      };
    },
    bgcard() {
      return this.$raphalUtils.getBgcardClasses(this.projectData);
    },
    iconColor() {
      return this.$raphalUtils.getIconColorClasses(this.projectData);
    },
    ionoColor() {
      return this.$raphalUtils.getIonoColorClasses(this.projectData.indiceIonoSphere);
    },
    calculateIono() {
      return this.$raphalUtils.getIonoText(this.projectData.indiceIonoSphere);
    },
    projectState() {
      return this.$raphalUtils.getProjectState(this.projectData);
    },
    scores() {
      return JSON.parse(this.projectData.scores);
    },
    isRtk() {
      return this.projectData.rtk === true; // && this.projectData.nbBalises !== 0 && this.projectData.indicateurBalise !== 2;
    },
    isEasyscale() {
      return this.$raphalUtils.isEasyscale(this.projectData);
    },
    isPurchaseDisabled() {
      return this.$raphalUtils.isPurchaseDisabled(this.projectData);
    },
    isExportDisabled() {
      return this.$raphalUtils.isExportDisabled(this.projectData);
    }
  },
  methods: {
    editComment() {
      this.editableComment = this.projectData.commentaire;
      this.isEditing = true;
      this.$nextTick(() => {
        this.$refs.commentInput?.focus();
      });
    },
    saveComment() {
      let query = gql`
        mutation updateProjectComment($id: Int!, $commentaire: String!) {
          updateProjectComment(id: $id, commentaire: $commentaire) {
            success
            message
          }
        }
      `;

      this.$apollo
        .mutate({
          mutation: query,
          client: 'apolloProjectClient',

          variables: {
            id: this.idprojet,
            commentaire: this.editableComment
          }
        })
        .then((data) => {
          if (data.data.updateProjectComment) {
            if (data.data.updateProjectComment.success == true) {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'success',
                title: data.data.updateProjectComment.message
              });
              this.projectData.commentaire = this.editableComment;
              this.isEditing = false;
            } else {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: data.data.updateProjectComment.message
              });
              this.isEditing = false;
            }
          } else {
            // eslint-disable-next-line
            Toast.fire({
              icon: 'error',
              title: data.data.updateProjectComment.message
            });
            this.isEditing = false;
          }
        })
        .catch((error) => {
          console.error(error);
          this.isEditing = false;
        });
    },
    delete_project() {
      // eslint-disable-next-line
      ToastConfirm.fire({
        title: "Voulez-vous vraiment supprimer ce projet ?"
      }).then((result) => {
        if (result.isConfirmed) {
          let query = gql`
            mutation deleteProject($id: Int!) {
              deleteProject(id: $id) {
                success
              }
            }
          `;

          this.$apollo
            .mutate({
              mutation: query,
              client: 'apolloProjectClient',

              variables: {
                id: this.idprojet
              }
            })
            .then((data) => {
              // Result
              if (data.data.deleteProject) {
                if (data.data.deleteProject.success == true) {
                  // eslint-disable-next-line
                  Toast.fire({
                    icon: 'success',
                    title: 'Opération effectuée avec succès !'
                  });
                  // let idproj = this.idprojet
                  // this.$parent.projectList= this.$parent.projectList.filter(function(proj) {

                  //     return proj.id != idproj;

                  // })
                } else {
                  // eslint-disable-next-line
                  Toast.fire({
                    icon: 'error',
                    title: 'Opération a échoué !'
                  });
                }
              } else {
                // eslint-disable-next-line
                Toast.fire({
                  icon: 'error',
                  title: 'Erreur !'
                });
              }
            })
            .catch((error) => {
              // Error
              console.error(error);
              // We restore the initial user input
            });
        }
      });
    },
    pad(n) {
      return n < 10 ? '0' + n.toString(10) : n.toString(10);
    },
    timestamp(d) {
      var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
      var time = [this.pad(d.getHours()), this.pad(d.getMinutes())].join(':');
      return [this.pad(d.getDate()), months[d.getMonth()], d.getFullYear()].join('/') + ' ' + time;
    },

    buyProject() {
      if (this.projectData.premiumProject == 1) {
        // eslint-disable-next-line
        ToastAlert.fire({
          title: "Projet déjà validé !"
        });
        return;
      }
      // eslint-disable-next-line
      ToastConfirm.fire({
        title: "Voulez-vous vraiment valider ce projet ?"
      }).then((result) => {
        if (result.isConfirmed) {
          const query = gql`
            mutation buyProject($id: Int!) {
              buyProject(id: $id) {
                success
                errors
              }
            }
          `;
          let that_ = this;
          this.$apollo
            .mutate({
              mutation: query,
              client: 'apolloProjectClient',
              variables: {
                id: this.idprojet
              }
            })
            .then((data) => {
              // Result
              if (data.data.buyProject) {
                if (data.data.buyProject.success == true) {
                  that_.validateProject(that_.projectData);
                  // eslint-disable-next-line
                  Toast.fire({
                    icon: 'success',
                    title: 'Opération effectuée avec succès !'
                  });
                  this.projectData.premiumProject = 1;
                  this.user.usedJetons += 1;
                } else {
                  // eslint-disable-next-line
                  Toast.fire({
                    icon: 'error',
                    title: JSON.parse(data.data.buyProject.errors).message
                  });
                }
              } else {
                // eslint-disable-next-line
                Toast.fire({
                  icon: 'error',
                  title: 'Erreur !'
                });
              }
            })
            .catch((error) => {
              // Error
              console.error(error);
              // We restore the initial user input
            });
        }
      });
    },
    toggleBookmark() {
      // let confirmMsg = "Voulez-vous suivre ce projet ?";
      let outputMsg = `Le projet "${this.projectData.nom}" vient d'être ajouté à la liste des projets suivis !`;
      if (this.projectData.bookmarked) {
        // confirmMsg = "Voulez-vous arrêter de suivre ce projet ?";
        outputMsg = `Le projet "${this.projectData.nom}" vient d'être enlevé de la liste des projets à suivre !`;
      }

      // if (confirm(confirmMsg) == true) {
        const query = gql`
          mutation toggleBookmark($id: Int!, $bookmarked: Boolean) {
            toggleBookmark(id: $id, bookmarked: $bookmarked) {
              success
              errors
            }
          }
        `;
        this.$apollo
          .mutate({
            mutation: query,
            client: 'apolloProjectClient',
            variables: {
              id: this.idprojet,
              bookmarked: !this.projectData.bookmarked
            }
          })
          .then((data) => {
            // Result
            if (data.data.toggleBookmark) {
              if (data.data.toggleBookmark.success == true) {
                this.$parent.refetchProjects();
                // eslint-disable-next-line
                Toast.fire({
                  icon: 'success',
                  title: outputMsg
                });
              } else {
                // eslint-disable-next-line
                Toast.fire({
                  icon: 'error',
                  title: JSON.parse(data.data.toggleBookmark.errors).message
                });
              }
            } else {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: 'Erreur !'
              });
            }
          })
          .catch((error) => {
            // Error
            console.error(error);
          });
      // } 
    },
    openExports() {
      this.$parent.$refs.modalExport.toggleModal(this.idprojet, this.projectData);
    },
    // copyProject(projet){
    //   let mes = '';
    //   navigator.clipboard.writeText(projet)
    //     .then(() => {
    //       mes = 'Le nom projet copié avec succès'

    //       // eslint-disable-next-line
    //       Toast.fire({
    //         icon: "success",
    //         title: mes
    //       });
    //     })
    //     .catch(err => {
    //       mes = 'Erreur lors de la copie du projet'
    //       console.error(mes,' :', err);
    //       // eslint-disable-next-line
    //       Toast.fire({
    //         icon: "error",
    //         title: mes
    //       });
    //     });

    // },
    copyProject(project) {
      const textArea = document.createElement('textarea');
      textArea.textContent = project;
      document.body.append(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        // eslint-disable-next-line
        Toast.fire({
          icon: 'success',
          title: 'Le nom projet ' + project + ' copié avec succès !'
        });
      } catch (error) {
        console.error('Error during copy :', error);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: 'Erreur lors de la copie du projet !'
        });
      }

      document.body.removeChild(textArea);
    },
    zoomToProject() {
      if (this.$parent.$refs.mapcontainer) {
        this.$parent.$refs.mapcontainer.flyToLocation(this.projectData.geom.coordinates);
      }
    }
  }  
};
</script>

<style scoped>
.wrapText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.item {
  padding: 5px 5px;
}

.item:first-child {
  padding-left: 9px;
}

.item:last-child {
  padding-right: 9px;
}

.ring2 {
  width: 32px;
  height: 32px;
  border-radius: 20px;

  background: #e2e8f0;

  transition: All 0.3s ease;
  overflow: hidden;
}

/* .ring:hover {
 
  transform: rotate(9deg) scale(1.05) translate(1px);
  animation: colors 1s ease infinite;
  background-size: 200% 200%;
  background-color: #3d8bff;
  border: 4px solid transparent;
  animation-direction: alternate;
} */

@keyframes colors {
  0% {
    background-position: 10% 0;
  }
  100% {
    background-position: 91% 100%;
  }
}

.avatar {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 100%;
  width: 100%;
}

/* You can add your own button or you could just, */
/* Override default button design to make it smaller. */
.horizontal >>> .v-hl-btn svg {
  padding: 2px;
  height: 24px;
  width: 24px;
}

.ribbon {
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 138px;
  padding: 5px 0;
  background-color: #37a8df;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font:
    700 10px/1 'Lato',
    sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -6px;
  right: -6px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -9px;
  top: 20px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}
/* zoom link */
.zoom-link {
  display: inline-block;
  width: 100%;
  color: #00b0bd;
  padding: 0.75rem 0;
}
.zoom-link:hover {
  color: #0a84ff;
  text-decoration: underline;
}
.bookmark-btn svg path {
  fill: #999999;
}
.bookmark-btn svg:hover path {
  fill: #99cccc;
}
.bookmark-btn.flag-on span svg path {
  fill: #37a8df;
}
.bookmark-btn.flag-on.shadow-raphal {
  --tw-shadow: 0 1px 5px 0 #37a8df;
}
.bookmark-btn.flag-on span svg:hover path {
  fill: #3485ad;
}
</style>
