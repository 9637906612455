<template>
  <div class="h-full w-full flex-grow">
    <modalMatos ref="modalMatosForm" :refresh-table="refreshTable" show-modal-sync="showmodal" />

    <t-table :headers="getHeader" :data="paginatedProjects" class="h-full">
      <template v-slot:thead="props">
        <thead :class="props.theadClass">
          <tr :class="props.trClass">
            <th v-for="(item, index) in props.data" :key="index" :class="{ hidden: item.text == 'id', [props.thClass]: true }">
              {{ item.text }}
            </th>
          </tr>
        </thead>
      </template>
      <template slot="row" slot-scope="props">
        <tr class="text-center" :class="[props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '']">
          <td class="hidden">
            {{ props.row.id }}
          </td>
          <td>
            {{ props.row.identifiant }}
          </td>
          <td>
            {{ props.row.gpsDevice.model }}
          </td>
          <td>
            {{ props.row.gpsDevice.serialNumber }}
          </td>
          <td>
            {{ props.row.smartPhone.model }}
          </td>
          <td>
            {{ props.row.smartPhone.serialNumber }}
          </td>
          <td>
            {{ props.row.prop.username }}
          </td>
          <td>
            {{ timestamp(new Date(props.row.creationDate)) }}
          </td>

          <td :class="['text-center']">
            <t-dropdown variant="raphal">
              <template slot="button">
                <svg
                  version="1.1"
                  viewBox="0 0 16 16"
                  class="text-gray-1 fill-current svg-icon svg-fill"
                  heigth="20"
                  style="width: 20px"
                >
                  <path
                    pid="0"
                    d="M13 7a2 2 0 1 1 .001 3.999A2 2 0 0 1 13 7zM8 7a2 2 0 1 1 .001 3.999A2 2 0 0 1 8 7zM3 7a2 2 0 1 1 .001 3.999A2 2 0 0 1 3 7z"
                  />
                </svg>
              </template>
              <div slot-scope="{ hide }">
                <button
                  class="block w-full px-4 py-2 text-left text-gray-800 hover:text-white hover:bg-gray-1"
                  @click.stop="editRow(props.row)"
                  @click="hide"
                >
                  Modifier
                </button>
                <button
                  class="block w-full px-4 py-2 text-left text-gray-800 hover:text-white hover:bg-gray-1"
                  @click.stop="deleteMatos(props.row)"
                  @click="hide"
                >
                  Supprimer
                </button>
              </div>
            </t-dropdown>
          </td>
        </tr>
      </template>
      <template slot="tfoot" slot-scope="{ tfootClass, trClass, tdClass, renderResponsive }">
        <tfoot :class="tfootClass">
          <tr :class="trClass">
            <td :class="tdClass" :colspan="renderResponsive ? 2 : 4">
              <t-pagination
                :hide-prev-next-controls="renderResponsive"
                :total-items="lenMatos"
                :per-page="nbrows"
                :class="{
                  'ml-auto': !renderResponsive,
                  'mx-auto': renderResponsive
                }"
                @change="getPage"
              />
            </td>
          </tr>
        </tfoot>
      </template>
    </t-table>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import modalMatos from './ModalMatos.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'PackMaterielManagement',
  components: { modalMatos },
  props: {
    nbrows: { type: Number, default: 10 }
  },
  data() {
    return {
      lenMatos: 0,
      showmodal: false,
      numPage: 1
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      roleName: 'Auth/roleUser',
      isRtk: 'Auth/isRtk'
    }),
    paginatedProjects() {
      if (this.getPackMateriel == null) {
        return [];
      }
      var slicedMatos = this.getPackMateriel.slice(this.nbrows * (this.numPage - 1), this.nbrows * this.numPage);
      return slicedMatos;
    },
    getHeader() {
      let header = [
        'id',
        'Identifiant',
        'Modèle GPS',
        'SN GPS',
        'Modèle Téléphone',
        'SN Téléphone',
        'Manager',
        'Date Création',
        'Actions'
      ];
      // console.log(header)

      return header;
    }
  },
  methods: {
    editRow(item) {
      this.showmodal = true;
      console.log(item);

      this.$refs.modalMatosForm.toggleModal(item, 'Modifier Matériel', true);
    },
    pad(n) {
      return n < 10 ? '0' + n.toString(10) : n.toString(10);
    },
    timestamp(d) {
      var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
      var time = [this.pad(d.getHours()), this.pad(d.getMinutes())].join(':');
      return [this.pad(d.getDate()), months[d.getMonth()], d.getFullYear()].join('/') + ' ' + time;
    },
    deleteMatos(item) {
      const index = this.getPackMateriel.indexOf(item);
      // eslint-disable-next-line
      ToastConfirm.fire({
        title: "Voulez-vous vraiment supprimer ce Matériel ?"
      }).then((result) => {
        if (result.isConfirmed) {
          this.getPackMateriel.splice(index, 1);
          this.$apollo
            .mutate({
              mutation: gql`
                mutation deleteMatos($id: Int!) {
                  deleteMatos(id: $id) {
                    success
                  }
                }
              `,
              variables: {
                id: item.id
              }
            })
            .then((data) => {
              // Result
              if (data.data.deleteMatos.success == true) {
                // eslint-disable-next-line
                Toast.fire({
                  icon: 'success',
                  title: 'Opération effectuée avec succès !'
                });
              } else {
                // eslint-disable-next-line
                Toast.fire({
                  icon: 'error',
                  title: 'erreur !'
                });
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    },
    getPage(num) {
      console.log(num);
      this.numPage = num;
    },
    refreshTable() {
      this.$apollo.queries.getPackMateriel.refetch();
    }
  },
  apollo: {
    $client: 'apolloProjectClient',
    getPackMateriel: {
      query: gql`
        query getMatosData {
          getPackMateriel {
            id
            identifiant
            creationDate

            smartPhone {
              id
              model
              serialNumber
            }
            gpsDevice {
              id
              model
              serialNumber
              macBleutooth
            }
            prop {
              id
              username
              email
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      result({ data }) {
        this.lenMatos = data.getPackMateriel.length;
      }
    }
  }
};
</script>

<style></style>
